import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";
import { useWindowSize } from "../hooks/useWindowSize";
import EmptyState from "./EmptyState";
import EmptyStatePlaceHolder from "../assets/svgs/empty_state_bg.svg";
import Button from "./Button";
import SubscriptionAccordian from "./SubscriptionAccordian";
import { useNavigate } from "@reach/router";
import PaymentModal from "../components/Modals/PaymentModal";
import Loader from "../components/Shimmer/Loader";
import { ErrorScreen } from "./ErrorScreen";
import _ from "lodash";

const SubscriptionTable = ({
  data,
  isLoading,
  error,
  showEmptyState = false,
  refreshData = () => {},
}) => {
  const { width } = useWindowSize();
  const isDesktop = width >= 728;
  const navigate = useNavigate();
  const [paymentPopupIndex, setPaymentPopupIndex] = useState(-1);

  if (error) return <ErrorScreen />;

  if (showEmptyState) {
    return (
      <EmptyState
        heading="No Subscription Yet!"
        subHeading={
          "You haven't subscribed to any program yet, to subscribe a program click on the below button."
        }
        cta={
          <Button className="!mt-4" onClick={() => navigate("/register")}>
            <div className="flex items-center px-3 py-1">
              <span>SUBSCRIBE PROGRAMS</span>
            </div>
          </Button>
        }
        image={
          <img
            alt="emptyStatePlaceHolder"
            className="py-9 max-h-80"
            src={EmptyStatePlaceHolder}
          />
        }
      />
    );
  }
  return (
    <div className={classNames("w-full transition-all duration-700")}>
      {isLoading ? (
        <div className="absolute top-0 right-0 flex items-center justify-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          {(data ?? []).filter((e) => e.show_pay_now_btn).length > 0 && (
            <div className="grid w-full grid-cols-1 bg-white sm:grid-cols-2 md:grid-cols-3 ">
              <div className="flex flex-col items-start pl-2 mt-6 md:pl-4 col-span-full">
                <div
                  className={classNames(
                    "flex text-lg leading-5 lg:text-xl lg:leading-6 text-left text-theme-black-color font-bold"
                  )}
                >
                  Overdue/Expiring Subscriptions
                </div>
                <div
                  className={classNames(
                    "flex text-xs lg:text-sm leading-5 text-left text-theme-black-300 tracking-tight "
                  )}
                >
                  All subscriptions which are overdue or will expire soon are
                  listed here.
                </div>
              </div>
              {data?.map((item, i) => {
                return item.show_pay_now_btn ? (
                  <SubscriptionAccordian
                    item={item}
                    key={i}
                    isDesktop={isDesktop}
                    onPayNowClick={() => {
                      setPaymentPopupIndex(i);
                    }}
                  />
                ) : null;
              })}
            </div>
          )}
          {(data ?? []).filter((e) => !e.show_pay_now_btn).length > 0 && (
            <div className="grid w-full grid-cols-1 mt-6 bg-white sm:grid-cols-2 md:grid-cols-3 ">
              <div className="flex flex-col items-start pl-2 mt-6 md:pl-4 col-span-full">
                <div
                  className={classNames(
                    "flex text-lg leading-5 lg:text-xl lg:leading-6 text-left text-theme-black-color font-bold"
                  )}
                >
                  Ongoing Subscriptions
                </div>
                <div
                  className={classNames(
                    "flex text-xs lg:text-sm leading-5 text-left text-theme-black-300 tracking-tight "
                  )}
                >
                  All ongoing subscriptions are listed here
                </div>
              </div>
              {data?.map((item, i) => {
                return !item.show_pay_now_btn ? (
                  <SubscriptionAccordian
                    item={item}
                    key={i}
                    isDesktop={isDesktop}
                    onPayNowClick={() => {
                      setPaymentPopupIndex(i);
                    }}
                  />
                ) : null;
              })}
            </div>
          )}
        </React.Fragment>
      )}
      {paymentPopupIndex !== -1 && (
        <PaymentModal
          open={paymentPopupIndex !== -1}
          title={data[paymentPopupIndex].name}
          entityProps={{
            entity_type: data[paymentPopupIndex].course_type,
            entity_id: data[paymentPopupIndex].course_id,
            part_payment_order:
              data[paymentPopupIndex]?.course_type === "pro_member"
                ? 1
                : _.head(
                    data[paymentPopupIndex]?.available_part_payment_option ?? []
                  )?.part_payment_order ?? 0,
          }}
          paymentEntityProps={{
            entity_type: data[paymentPopupIndex]?.course_type,
            entity_id:
              data[paymentPopupIndex].course_id ??
              data[paymentPopupIndex].entity_details?.entity_id ??
              data[paymentPopupIndex].entity_id,
          }}
          availablePartPaymentOptions={
            data[paymentPopupIndex]?.available_part_payment_option ?? [
              { label: "", part_payment_order: 0 },
            ]
          }
          onClose={() => {
            refreshData();
            setPaymentPopupIndex(-1);
          }}
        />
      )}
    </div>
  );
};

SubscriptionTable.propTypes = {
  data: PropTypes.array,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  refreshData: PropTypes.func,
  showEmptyState: PropTypes.bool,
};
export default SubscriptionTable;
